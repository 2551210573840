<template>
   <div>
   
    <div id="myChart" class="chart" :style="{width: '100%', height: '100%'}"></div>
    </div>
  </template>
  
  <script>
  import * as echarts from 'echarts';
  export default {
    name: 'xwPassengerFlow',
   
    data () {
      return {
       
      }
    },
    mounted () {
      this.loadLine()
    },
    methods: {
      loadLine () {
      

        const option = {
  series: [
    {
      type: 'treemap',
      data: [
        {
          name: 'nodeA',
          value: 10,
          children: [
            {
              name: 'nodeAa',
              value: 4
            },
            {
              name: 'nodeAb',
              value: 6
            }
          ]
        },
        {
          name: 'nodeB',
          value: 20,
          children: [
            {
              name: 'nodeBa',
              value: 20,
              children: [
                {
                  name: 'nodeBa1',
                  value: 20
                }
              ]
            }
          ]
        }
      ]
    }
  ]
};
       
  
        this.myChartOne = echarts.init(document.getElementById('myChart'))
        this.myChartOne.setOption(option)
      }
    }
  }
  </script>