<template>
  <div>

    <div id="bingtu" class="chart" :style="{width: '100%', height: '400px','min-width':'500px'}"></div>
  </div>
</template>
   
   <script>
import * as echarts from 'echarts';
import { purchaseac } from '../../../api/api';
export default {
  name: 'xwPassengerFlow',
  props: {
    bingtuData: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  data() {
    return {
      list: []
    }
  },
  mounted() {
    this.loadLine()
  },
  watch: {
    bingtuData(val) {
      this.loadLine();
    }
  },

  methods: {
    loadLine() {
      var legend_list = [];
      var series_list = [];
      if(Array.isArray(this.bingtuData)) {
        this.bingtuData.forEach(e => {
          //分类
          if(e.lastClassName) {
            legend_list.push(e.lastClassName)
          } else {
            legend_list.push('其他')
          }
          //分类数据
          series_list.push({
            value: parseFloat(e.proportion).toFixed(0),
            name: e.lastClassName || '其他'
          })
        })
      }
      console.log('bingtuData', this.bingtuData, series_list, legend_list)
      // 渐变色的配置对象
      var color1 = new echarts.graphic.LinearGradient(
        1, 0, 0, 0, // 这四个参数分别表示渐变的起点 (x1, y1) 与终点 (x2, y2)
        [
          { offset: 1, color: '#98C6FF' },   // 0% 处的颜色
          { offset: 0, color: "rgba(152,198,255,0)" }   // 100% 处的颜色
        ]
      );
      var color2 = new echarts.graphic.LinearGradient(
        1, 0, 0, 0, // 这四个参数分别表示渐变的起点 (x1, y1) 与终点 (x2, y2)
        [
          { offset: 1, color: '#BFC9FE' },   // 0% 处的颜色
          { offset: 0, color: '#FAFAFF' }   // 100% 处的颜色
        ]
      );
      var color3 = new echarts.graphic.LinearGradient(
        1, 0, 0, 0, // 这四个参数分别表示渐变的起点 (x1, y1) 与终点 (x2, y2)
        [
          { offset: 1, color: '#79DED2' },   // 0% 处的颜色
          { offset: 0, color: '#FAFFFB' }   // 100% 处的颜色
        ]
      );
      var color4 = new echarts.graphic.LinearGradient(
        1, 0, 0, 0, // 这四个参数分别表示渐变的起点 (x1, y1) 与终点 (x2, y2)
        [
          { offset: 1, color: '#FFCD8B' },   // 0% 处的颜色
          { offset: 0, color: '#FFFBFA' }   // 100% 处的颜色
        ]
      );
      const option = {
        grid: {
          left: '3%',  // 图表距离容器左侧的距离
          right: '4%', // 图表距离容器右侧的距离
          bottom: 0, // 图表距离容器底部的距离
          containLabel: true // 包含标签
        },
        title: {
          //   text: '天气情况统计',
        },
        color: ['#7cb5ec', '#434348', '#90ed7d', '#f7a35c', '#8085e9',
          '#f15c80', '#e4d354', '#80c5e8', '#8d4653', '#92e8e1', '#fdcasd'],
        tooltip: {
          formatter: '{b}：{c}%',
        },
        // legend: {
        //   // orient: 'vertical',
        //   bottom: 0,
        //   data: legend_list,
        //   left: 'right',
        //   height: '100px',  //图例容器的高度，超过会换行展示
        //   width: '100%',
        //   textStyle: {
        //     width: '20%',
        //   }
        // },
        roam: true,
        viewControl: {
          // projection: 'orthographic'
        },
        series: [
          {
            type: 'pie',
            radius: '70%',
            // selectedMode: 'single',
            avoidLabelOverlap: true,
            minAngle: 20,
            data: series_list,
            label: {
              //   alignTo: 'edge',
              formatter: '{b}：{c}%',
              //   minMargin: 2,
              //   edgeDistance: 10,
              //   lineHeight: 15,
              //   rich: {
              //     time: {
              //       fontSize: 10,
              //       color: '#999'
              //     }
              //   }
            },
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      };


      this.myChartOne = echarts.init(document.getElementById('bingtu'))
      this.myChartOne.showLoading();
      this.$nextTick(() => {
        this.myChartOne.hideLoading();
        this.myChartOne.setOption(option)
      })
    }
  }
}
   </script>

