<template>
  <div class="diadin">
    <!--  -->
    <div style="text-align:right;margin:-10px 0 10px 0">（本产品内容和数据仅供参考，不得用于商业用途等其他行为)</div>
    <div class="home-shuju">
      <div class="home-shuju-head">
        <div class="home-shuju-head-left">
          <div class="home-shuju-head-left-color">
          </div>
          <div class="home-shuju-head-left-page font-18">
            数据大盘
          </div>
          <el-select v-model="dataSourceValue" placeholder="请选择" class="select_style" @change="changeSource(1)">
            <el-option v-for="item in sourceList" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="home-shuju-head-right font-10">
          <div @click="changeShujuData(1,1)" :class="shuju_date == 1 ?  'home-shuju-head-right-item home-shuju-head-right-item-choose ' :'home-shuju-head-right-item ' ">
            7日
          </div>
          <el-divider direction="vertical" style="color: #E7E7E7;"></el-divider>
          <div @click="changeShujuData(1,2)" :class="shuju_date == 2 ?  'home-shuju-head-right-item home-shuju-head-right-item-choose ' :'home-shuju-head-right-item ' ">
            30日
          </div>
        </div>
      </div>
      <div class="home-shuju-content">
        <div class="home-shuju-content-xse" style="margin-left:0">
          <div class="home-shuju-content-xse-title font-18">
            销售额指数
          </div>
          <div class="home-shuju-content-xse-num font-40">
            {{sjhb.saleAmount | formatMoney}}
          </div>
          <div class="home-shuju-content-xse-hb font-12">
            环比
            <div class="home-shuju-content-xse-hb-up" v-if="sjhb.saleAmountIncrease>0">
              {{sjhb.saleAmountIncrease}}%<img style="width: 12px;height: 12px;margin-left:8px" src="../../assets//images/shang.png" alt=""></div>
            <div class="home-shuju-content-xse-hb-down" v-if="sjhb.saleAmountIncrease<0">
              {{sjhb.saleAmountIncrease}}%<img style="width: 12px;height: 12px;margin-left:8px" src="../../assets//images/xia.png" alt=""></div>
          </div>
          <div class="home-shuju-content-xse-hb-zero" v-if="sjhb.saleAmountIncrease == 0">
            0%
          </div>

        </div>
        <div :class="i == 0 ? 'home-shuju-content-kdj home-shuju-content-xse' : ( i == 1  ? ' home-shuju-content-xse home-shuju-content-xsl ': 'home-shuju-content-xse home-shuju-content-dxsp')" v-for="(e,i) in sjlist" :key="i">
          <div class="home-shuju-content-xse-title font-18">
            {{ e.title }}
          </div>
          <div class="home-shuju-content-xse-num font-40">
            {{ e.num | formatMoney }}
          </div>
          <div class="home-shuju-content-xse-hb font-12">
            环比
            <div class="home-shuju-content-xse-hb-up" v-if=" e.hb >0">
              {{ e.hb }}%<img style="width: 12px;height: 12px;margin-left:8px" src="../../assets//images/shang.png" alt=""></div>
            <div class="home-shuju-content-xse-hb-down" v-if=" e.hb <0">
              {{ e.hb }}%<img style="width: 12px;height: 12px;margin-left:8px" src="../../assets//images/xia.png" alt=""></div>
          </div>
          <div class="home-shuju-content-xse-hb-zero" v-if=" e.hb  == 0">
            0%
          </div>

        </div>

      </div>
    </div>
    <div class="home-dapan">
      <!--品类大盘  -->
      <div class="home-dapan-pinlei">
        <div class="home-dapan-pinlei-head">
          <div class="home-dapan-pinlei-head-left">
            <div class="home-dapan-pinlei-head-left-color">
            </div>
            <div class="home-dapan-pinlei-head-left-page font-18">
              品类大盘
            </div>
            <el-select v-model="showMain" placeholder="请选择" class="select_style" clearable @change='saleRank();getSaleAmtAndSumTrend()'>
              <el-option v-for="item in firsrTypeList" :key="item.value" :label="item.label" :value="item.label">
              </el-option>
            </el-select>
          </div>
        </div>
        <qx style="height: 400px;" ref="qx"></qx>
      </div>
      <!--销量大盘  -->
      <div class="home-dapan-xiaoliang">
        <div class="home-dapan-xiaoliang-head">
          <div class="home-dapan-xiaoliang-head-left">
            <div class="home-dapan-xiaoliang-head-left-color">
            </div>
            <div class="home-dapan-xiaoliang-head-left-page font-18">
              销量大盘
            </div>
          </div>
        </div>
        <bingtu :bingtuData="sjb"></bingtu>
      </div>
    </div>
    <div class="home-rexiao">
      <!-- 热销店铺排行 -->
      <div class="home-rexiao-dianpu">
        <div class="home-rexiao-dianpu-head">
          <div class="home-rexiao-dianpu-head-left">
            <div class="home-rexiao-dianpu-head-left-color">
            </div>
            <div class="home-rexiao-dianpu-head-left-page font-18">
              热销店铺排行
            </div>
            <el-select v-model="dianpu_source_value" placeholder="请选择" class="select_style" @change="changeSource(2)">
              <el-option v-for="item in sourceList" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </div>
          <div class="home-rexiao-dianpu-head-right font-10">
            <div @click="changeShujuData(2,0)" :class=" rexiao_dianpu_date == 0 ? 'home-rexiao-danpin-head-right-item home-rexiao-danpin-head-right-item-choose' : 'home-rexiao-danpin-head-right-item' ">
              昨日
            </div>
            <el-divider direction="vertical" style="color: #E7E7E7;"></el-divider>
            <div @click="changeShujuData(2,1)" :class=" rexiao_dianpu_date == 1 ? 'home-rexiao-danpin-head-right-item home-rexiao-danpin-head-right-item-choose' : 'home-rexiao-danpin-head-right-item' ">
              7日
            </div>
            <el-divider direction="vertical" style="color: #E7E7E7;"></el-divider>
            <div @click="changeShujuData(2,2)" :class=" rexiao_dianpu_date == 2 ? 'home-rexiao-danpin-head-right-item home-rexiao-danpin-head-right-item-choose' : 'home-rexiao-danpin-head-right-item' ">
              30日
            </div>
          </div>
        </div>
        <div class="home-rexiao-dianpu-content">
          <div v-for="(item,index) in rexiao_dianpu_list " :key="index" class="home-rexiao-danpin-content-list" @click="tzxq(item.shopId)">
            <div class="home-rexiao-danpin-content-item">
              <div class="home-rexiao-danpin-content-item-img">
                <img :src="item.cover || require('../../assets/imgs/empty.png')" class="home-rexiao-danpin-content-item-img-main" alt="">
                <img v-if="index==0" class="home-rexiao-danpin-content-item-img-price" src="../../assets/images/Slice16.png" alt="">
                <img v-if="index==1" class="home-rexiao-danpin-content-item-img-price" src="../../assets/images/Slice17.png" alt="">
                <img v-if="index==2" class="home-rexiao-danpin-content-item-img-price" src="../../assets/images/Slice18.png" alt="">
              </div>
              <div class="home-rexiao-danpin-content-item-title font-14">
                {{ item.shopName}}
              </div>
              <div class="home-rexiao-danpin-content-item-sell">
                <div class="home-rexiao-danpin-content-item-sell-title font-12">
                  销量
                </div>
                <div class="home-rexiao-danpin-content-item-sell-num font-18">
                  {{ item.saleAmount | formatMoney}}
                </div>
              </div>
            </div>
            <el-divider></el-divider>
          </div>
        </div>
      </div>
      <!--热销单品排行  -->
      <div class="home-rexiao-danpin">
        <div class="home-rexiao-danpin-head">
          <div class="home-rexiao-danpin-head-left">
            <div class="home-rexiao-danpin-head-left-color">
            </div>
            <div class="home-rexiao-danpin-head-left-page font-18">
              热销单品排行
            </div>
            <el-select v-model="danpin_source_value" placeholder="请选择" class="select_style" @change="changeSource(3)">
              <el-option v-for="item in sourceList" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </div>
          <div class="home-rexiao-danpin-head-right">
            <div @click="changeShujuData(3,0)" :class=" rexiao_danpin_date == 0 ? 'home-rexiao-danpin-head-right-item home-rexiao-danpin-head-right-item-choose' : 'home-rexiao-danpin-head-right-item' ">
              昨日
            </div>
            <el-divider direction="vertical" style="color: #E7E7E7;"></el-divider>
            <div @click="changeShujuData(3,1)" :class=" rexiao_danpin_date == 1 ? 'home-rexiao-danpin-head-right-item home-rexiao-danpin-head-right-item-choose' : 'home-rexiao-danpin-head-right-item' ">
              7日
            </div>
            <el-divider direction="vertical" style="color: #E7E7E7;"></el-divider>
            <div @click="changeShujuData(3,2)" :class=" rexiao_danpin_date == 2 ? 'home-rexiao-danpin-head-right-item home-rexiao-danpin-head-right-item-choose' : 'home-rexiao-danpin-head-right-item' ">
              30日
            </div>
          </div>
        </div>
        <div class="home-rexiao-danpin-content">
          <div v-for="(item,index) in rexiao_danpin_list " :key="index" class="home-rexiao-danpin-content-list" @click="tzxqa(item.productId)">
            <div class="home-rexiao-danpin-content-item">
              <div class="home-rexiao-danpin-content-item-img">
                <img :src="item.imageUrl || require('../../assets/imgs/empty.png')  " class="home-rexiao-danpin-content-item-img-main" alt="">
                <img v-if="index==0" class="home-rexiao-danpin-content-item-img-price" src="../../assets/images/Slice16.png" alt="">
                <img v-if="index==1" class="home-rexiao-danpin-content-item-img-price" src="../../assets/images/Slice17.png" alt="">
                <img v-if="index==2" class="home-rexiao-danpin-content-item-img-price" src="../../assets/images/Slice18.png" alt="">
              </div>
              <div class="home-rexiao-danpin-content-item-title font-14">
                {{ item. productName}}
              </div>
              <div class="home-rexiao-danpin-content-item-sell">
                <div class="home-rexiao-danpin-content-item-sell-title font-12">
                  销量
                </div>
                <div class="home-rexiao-danpin-content-item-sell-num font-18">
                  {{ item.saleAmount | formatMoney}}
                </div>
              </div>
            </div>
            <el-divider></el-divider>
          </div>
        </div>
      </div>
    </div>
    <el-dialog :visible.sync="show_vip" width="20%" custom-class="dialogwidth">
      <div style="text-align:center;align-items:center;min-width:300px">
        <div>您还未开通会员，请联系客服开通</div>
        <img src="../../assets/imgs/kf_qr.png" style="width:220px;height:220px" alt="">
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="show_vip = false">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
    
    <script>
import { getUserInfo, getHotSaleShopRank, getTopOneData, saleRank, getAllCatData, getSaleAmtAndSumTrend, getStatisticDate, getHotSaleProductRank, getFirstCategory } from "../../api/api";
import rectangle from "../../components/rectangle";
import qx from "../../components/qx";
import bingtu from './components/bingtu.vue';
export default {
  components: {
    rectangle, qx, bingtu,
  },
  data() {
    return {
      formInline: {
        name: "",
        status: "0",
      },
      //数据大盘数据
      sjlist: [],
      shuju_date: 1,
      //饼图数据
      sjb: [],

      ullist: [
        {
          img: require("../../assets/images/doc.png"),
          titile: "查商品",
          introduce: "在已经上架的商品中洞悉市场行情找到潜在的机会",
          list: [
            {
              title: "跨境热销榜",
              url: "/buffer?type=1&status=1",
            },
            {
              title: "持续热销榜",
              url: "/buffer?type=1&status=1",
            },
            {
              title: "Temu热销榜",
              url: "/buffer?type=1&status=1",
            },
            {
              title: "SHein热销榜",
              url: "/buffer?type=1&status=1",
            },
            {
              title: "热销新品榜",
              url: "/buffer?type=1&status=1",
            },
            {
              title: "潜力爆品榜",
              url: "/buffer?type=1&status=1",
            },
            {
              title: "活动热销榜",
              url: "/buffer?type=1&status=1",
            },
          ],
        },
        {
          img: require("../../assets/images/doc.png"),
          titile: "查店铺",
          introduce: "从海量店铺中找到潜力店铺分析竞争对手",
          list: [
            {
              title: "跨境热店榜",
              url: "/buffer?type=2&status=1",
            },
            {
              title: "持续热店榜",
              url: "/buffer?type=2&status=1",
            },
            {
              title: "Temu热店榜",
              url: "/buffer?type=2&status=1",
            },
            {
              title: "SHein热店榜",
              url: "/buffer?type=2&status=1",
            },
            {
              title: "新店热度榜",
              url: "/buffer?type=2&status=1",
            },
            {
              title: "热搜热店榜",
              url: "/buffer?type=2&status=1",
            },
            {
              title: "活动热店榜",
              url: "/buffer?type=2&status=1",
            },
          ],
        },
      ],
      upproject: false,
      dialogVisible: false,
      finishId: "",
      field: "",
      sort: "",
      curPage: 1,
      pageSize: 10,
      totale: 0,
      tableData: [],
      sjhb: {},
      plb: [],
      sjsj: '',
      plsj: '',
      //品牌大类 选择相关
      firsrTypeList: [],
      showMain: '',
      //平台选择
      sourceList: [
        {
          value: 'temu',
          label: 'temu'
        },
        {
          value: 'shein',
          label: 'shein'
        }
      ],
      dataSourceValue: 'temu',
      //品类右侧数据
      pldata: {},
      //热销单品数据
      rexiao_danpin_list: [],
      rexiao_danpin_date: 2,
      danpin_source_value: 'temu',
      //热销店铺数据
      rexiao_dianpu_list: [],
      rexiao_dianpu_date: 2,
      dianpu_source_value: 'temu',
      show_vip: false,
      user: {},
    };
  },
  computed: {
    is_vip() {
      return this.user && this.user.level && this.user.level > 10
    },
    is_svip() {
      return this.user && this.user.level && this.user.level > 20
    }
  },
  created() {
    this.getHotShop(2);
    this.getHotProduct(2)
    this.sjbiao()
    this.plbiao(2)
    this.getUser()
    console.log('env', process.env.NODE_ENV)
  },
  methods: {
    //获取用户信息
    async getUser() {
      let token = localStorage.getItem('h5_token');
      console.log('token', token);
      if(token) {
        const { data: res } = await getUserInfo();
        if(res.code == '0000') {
          const { data } = res;
          const { user, userThird } = data
          this.user = user;
          console.log('confirmLoginByMobile', res, data);
          localStorage.setItem("h5_token", data.sessionId)
          localStorage.setItem("token", user.sessionId)
          localStorage.setItem("user", JSON.stringify(user))
          //5.登录成功之后,跳转到/home页面中
          //   this.$router.push("/index")
          //     this.$router.push({
          //       path: '/home',
          //       query: {
          //       }
          //     })
        }
      }
    },
    //切换平台
    changeSource(data) {
      if(data == 1) {
        this.sjbiao();
      } else if(data == 2) {
        this.getHotShop(this.rexiao_dianpu_date);
      } else {
        this.getHotProduct(this.rexiao_danpin_date);
      }
    },
    changeShujuData(type, data) {
      if(type == 1) {
        this.shuju_date = data;
        this.sjbiao()
      } else if(type == 2) {
        this.rexiao_dianpu_date = data;
        this.getHotShop(data)
      } else {
        this.rexiao_danpin_date = data;
        this.getHotProduct(data)
      }
    },

    tzly(e) {
      this.$router.push(e)
      sessionStorage.setItem("activeMenu", e)
    },
    tzxq(e) {
      if(!(this.is_svip || this.is_vip)) {
        this.show_vip = true;
        return;
      }
      this.$router.push({
        path: '/shopdeild',
        query: {
          id: e,
          dateType: this.rexiao_dianpu_date
        }
      })
      sessionStorage.setItem("activeMenu", '/shopdeild')
    },
    tzxqa(e) {
      if(!(this.is_svip || this.is_vip)) {
        this.show_vip = true;
        return;
      }
      this.$router.push({
        path: '/productid',
        query: {
          id: e,
          dateType: this.rexiao_danpin_date
        }
      })
      sessionStorage.setItem("activeMenu", '/productid')
    },

    async getStatisticDate(e, i) {
      let param = {};
      // param["page"] = this.curPage;
      param["dateType"] = e;
      param["page"] = 1;
      param["pagesize"] = 25;
      param["field"] = '';
      param["sort"] = '';

      param["firstCategory"] = "";

      const { data: res } = await getStatisticDate(param);
      let a = res.data
      if(i == 1) {
        this.sjsj = a
      }
      if(i == 2) {
        this.plsj = a
      }
    },
    async getHotShop(e) {
      let param = {};
      // param["page"] = this.curPage;
      param["dateType"] = e;
      param["page"] = 1;
      param["pagesize"] = 25;
      param["field"] = this.field;
      param["sort"] = this.sort;
      param["firstCategory"] = "服装、鞋靴和珠宝饰品";
      param['source'] = this.dianpu_source_value || 'temu';
      const { data: res } = await getHotSaleShopRank(param);
      this.rexiao_dianpu_list = [].concat(res.data);
    },
    async getHotProduct(e) {
      let param = {};
      // param["page"] = this.curPage;
      param["dateType"] = e;
      param["page"] = 1;
      param["pagesize"] = 25;
      param["field"] = this.field;
      param["sort"] = this.sort;
      param['source'] = this.danpin_source_value || 'temu';
      param["firstCategory"] = "服装、鞋靴和珠宝饰品";

      const { data: res } = await getHotSaleProductRank(param);
      this.rexiao_danpin_list = [].concat(res.data);
    },

    async plbiao(e) {
      let param = {};
      // param["page"] = this.curPage;
      param["dateType"] = e;
      param["page"] = 1;
      param["pagesize"] = 10;
      param["field"] = '';
      param["sort"] = '';

      param["firstCategory"] = "";

      const { data: res } = await getAllCatData(param);

      this.pldata = res.data;
    },
    async getSaleAmtAndSumTrend() {
      let param = {};
      // param["page"] = this.curPage;
      param["dateType"] = this.shuju_date;
      param["page"] = 1;
      param["pagesize"] = 10;
      param["field"] = '';
      param["sort"] = '';
      param['source'] = this.dataSourceValue || 'temu';
      param["firstCategory"] = this.showMain || '';
      const { data: res } = await getSaleAmtAndSumTrend(param);

      this.$nextTick(() => {
        this.plb = res.data;
        this.$refs.qx.list = res.data
      })
    },
    async sjbiao() {
      let param = {};
      // param["page"] = this.curPage;
      param["dateType"] = this.shuju_date;
      param["page"] = 1;
      param["pagesize"] = 10;
      param["field"] = '';
      param["sort"] = '';
      param['source'] = this.dataSourceValue || 'temu';
      param["firstCategory"] = "服装、鞋靴和珠宝饰品";
      const { data: res } = await getTopOneData(param);

      this.sjhb = res.data;
      this.sjlist = []
      let a = {
        title: '销售量指数',
        num: res.data.saleNum,
        hb: res.data.saleNumIncrease
      }
      let b = {
        title: '平均客单价',
        num: res.data.avgPrice,
        hb: res.data.avgPriceIncrease
      }
      let c = {
        title: '动销商品指数',
        num: res.data.productAmount,
        hb: res.data.productAmountIncrease
      }
      this.sjlist.push(b)
      this.sjlist.push(a)
      this.sjlist.push(c)
      this.getFirstCategory();
    },
    async getFirstCategory() {
      let param = {};
      param['source'] = this.dataSourceValue || 'temu';
      const { data: res } = await getFirstCategory(param);
      this.showMain = '';
      if(res.code == '0000') {
        this.firsrTypeList = [].concat(res.data);
        this.saleRank();
        this.getSaleAmtAndSumTrend();
      }
    },
    async saleRank() {
      let param = {};
      // param["page"] = this.curPage;
      param["dateType"] = this.shuju_date;
      param["page"] = 1;
      param["pagesize"] = 10;
      param["field"] = '';
      param["sort"] = '';
      param['source'] = this.dataSourceValue || 'temu'
      param["firstCategory"] = this.showMain || '';
      const { data: res } = await saleRank(param);

      this.sjb = res.data;

      this.sjb = this.sjb.sort((a, b) => b.proportion - a.proportion)
    },
    // 新建计划弹框
    project() {
      this.upproject = true;
      this.$refs[formName].resetFields();
    },
    //选择状态
    statusBoutton(e) {
      this.formInline.status = e.id;
    },
    // 结束按钮
    finish(e) {
      this.dialogVisible = true;
      this.finishId = e.id;
    },
    // 确认结束
    finishBont() {
      this.dialogVisible = false;
      // this.finishId 当前选择结束的ID
    },
    // 新增计划
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if(valid) {
          alert("submit!");
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 重置表单
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    details() {
      // this.$router.push({
      //   path: "/workbench/details",
      //   query: {},
      // });
    },
    details1() {
      this.$router.push({
        path: "/shopdeild",
        query: {},
      });
    },
  },
};
</script>
    
    <style lang="less" scoped>
/deep/ .dialogwidth {
  min-width: 300px;
}
.home {
  padding: 12px;
  &-shuju {
    padding: 16px;
    background: #ffffff;
    &-head {
      justify-content: space-between;
      display: flex;
      &-left {
        display: flex;
        align-items: center;
        &-color {
          display: inline-block;
          width: 4px;
          background-color: #4975e9;
          height: 18px;
          margin-right: 5px;
        }
        &-page {
          font-weight: bold;
          //   font-size: 20px;
          line-height: 23px;
          font-family: PingFang SC-Bold;
          color: #333;
        }
      }
      &-right {
        display: flex;
        // font-size: 12px;
        color: #767676;
        align-items: center;
        &-item {
          cursor: pointer;

          &-choose {
            color: #4975e9;
          }
        }
      }
    }
    &-content {
      margin: 20px 0 32px 0;
      display: flex;
      &-kdj {
        background-image: url("../../assets/imgs/kdj.png") !important; /* 修改路径以指向你的图片 */
      }
      &-xsl {
        background-image: url("../../assets/imgs/sxl.png") !important; /* 修改路径以指向你的图片 */
      }
      &-dxsp {
        background-image: url("../../assets/imgs/dxsp.png") !important; /* 修改路径以指向你的图片 */
      }
      &-xse {
        margin-left: 28px;
        padding: 19px 31px;
        background-image: url("../../assets/imgs/sxe.png"); /* 修改路径以指向你的图片 */
        background-size: cover; /* 背景图片覆盖整个元素 */
        background-repeat: no-repeat; /* 背景图片不重复 */
        background-position: center; /* 背景图片居中 */
        width: 321px; /* 宽度根据需要设置 */
        height: 166px; /* 高度根据需要设置 */
        &-title {
          font-weight: bold;
          //   font-size: 20px;
          color: #1b3256;
          line-height: 23px;
        }
        &-num {
          margin-top: 11px;
          //   font-size: 42px;
          color: #1b3256;
          line-height: 49px;
          font-weight: bold;
        }
        &-hb {
          //   font-size: 14px;
          color: #767676;
          line-height: 16px;
          display: flex;
          margin-top: 12px;
          &-up {
            margin-left: 10px;
            color: #e34d59;
          }
          &-down {
            margin-left: 10px;
            color: #00a870;
          }
          &-zero {
            margin-left: 10px;
          }
        }
      }
    }
  }
  &-dapan {
    display: flex;
    &-pinlei {
      padding: 16px;
      margin-top: 16px;
      width: 690px;
      margin-right: 17px;
      background: #ffffff;
      &-head {
        justify-content: space-between;
        display: flex;
        &-left {
          display: flex;
          align-items: center;
          &-color {
            display: inline-block;
            width: 4px;
            background-color: #4975e9;
            height: 18px;
            margin-right: 5px;
          }
          &-page {
            font-weight: bold;
            // font-size: 20px;
            line-height: 23px;
            font-family: PingFang SC-Bold;
            color: #333;
          }
        }
      }
    }
    &-xiaoliang {
      padding: 16px;
      margin-top: 16px;
      width: 693px;
      background: #ffffff;
      &-head {
        justify-content: space-between;
        display: flex;
        &-left {
          display: flex;
          align-items: center;
          &-color {
            display: inline-block;
            width: 4px;
            background-color: #4975e9;
            height: 18px;
            margin-right: 5px;
          }
          &-page {
            font-weight: bold;
            // font-size: 20px;
            line-height: 23px;
            font-family: PingFang SC-Bold;
            color: #333;
          }
        }
      }
    }
  }
  &-rexiao {
    margin-top: 16px;
    display: flex;
    &-dianpu {
      padding: 16px;
      background: #ffffff;
      width: 692px;
      &-head {
        justify-content: space-between;
        display: flex;
        &-left {
          display: flex;
          align-items: center;
          &-color {
            display: inline-block;
            width: 4px;
            background-color: #4975e9;
            height: 18px;
            margin-right: 5px;
          }
          &-page {
            font-weight: bold;
            // font-size: 20px;
            line-height: 23px;
            font-family: PingFang SC-Bold;
            color: #333;
          }
        }
        &-right {
          display: flex;
          //   font-size: 12px;
          color: #767676;
          align-items: center;
          &-item {
            cursor: pointer;
          }
        }
      }
      &-content {
        margin-top: 16px;
      }
    }
    &-danpin {
      padding: 16px;
      background: #ffffff;
      width: 692px;
      margin-left: 16px;
      &-head {
        justify-content: space-between;
        display: flex;
        &-left {
          display: flex;
          align-items: center;
          &-color {
            display: inline-block;
            width: 4px;
            background-color: #4975e9;
            height: 18px;
            margin-right: 5px;
          }
          &-page {
            font-weight: bold;
            // font-size: 20px;
            line-height: 23px;
            font-family: PingFang SC-Bold;
            color: #333;
          }
        }
        &-right {
          display: flex;
          //   font-size: 12px;
          color: #767676;
          align-items: center;
          &-item {
            cursor: pointer;
            &-choose {
              color: #4975e9;
            }
          }
        }
      }
      &-content {
        &-list {
          /deep/ .el-divider--horizontal {
            margin: 12px 0;
          }
        }

        margin-top: 16px;
        &-item {
          display: flex;
          color: #333333;
          height: 60px;
          align-items: center;
          justify-content: center;
          &-img {
            margin-left: 16px;
            position: relative;
            &-main {
              height: 60px;
              width: 60px;
              border-radius: 10px;
              box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
            }
            &-price {
              width: 19px;
              height: 21px;
              position: absolute;
              top: -4px;
              left: -4px;
            }
          }
          &-title {
            margin-left: 14px;
            // font-size: 16px;
            line-height: 19px;
            width: 100%;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            line-height: 16px; /* 这里的行高要和你的字体大小相匹配 */
            max-height: 48px; /* 3行行高的和 */
            position: relative;
            text-overflow: ellipsis;
          }
          &-sell {
            text-align: right;
            margin-right: 14px;
            margin-left: 14px;
            &-num {
              margin-top: 4px;
              min-width: 28px;
            }
          }
        }
      }
    }
  }
}

/deep/ .el-carousel__item {
  background-color: #fff !important;
}
.zw {
  width: 100%;
  height: 200px;
}
/deep/.is-active {
  background-color: transparent !important;
}
/deep/.el-carousel__arrow {
  display: none !important;
}
.select_style {
  width: 200px;
  margin-left: 10px;
  /deep/.el-input {
    width: 200px;
  }
  /deep/.el-input__inner {
    height: 25px;
    line-height: 25px;
    width: 200px;
  }
  /deep/.el-input__icon {
    line-height: 25px;
  }
}
</style>